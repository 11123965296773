<template>
  <v-form v-model="formValid">
    <v-subheader class="font-italic font-weight-light py-1"
      >Generale</v-subheader
    >
    <v-row no-gutters class="py-1">
      <v-col>
        <v-text-field
          v-model="description"
          :rules="[max255CharRule, presenceRule]"
          dense
          filled
          label="Descrizione"
          hide-details="auto"
          @input="updateField('description', $event)"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import Vue from "vue";
import paymentConditionForm from "@/services/paymentTerms/paymentCondition.form.js";

export default {
  name: "GeneralFormTab",
  components: {},
  data: function () {
    return {
      description: undefined,
      loadingPaymentCondition: false,
      formValid: false,
      max255CharRule: (v) => {
        if (v) {
          return v.length <= 255 || "Al massimo 255 caratteri";
        } else {
          return true;
        }
      },
      presenceRule: (v) => {
        return !!v || "Il campo è obbligatorio";
      },
    };
  },
  props: {
    bus: {
      type: Object,
      default: function () {
        return new Vue();
      },
    },
  },
  mounted: function () {
    this.handleObjectChanges(paymentConditionForm.paymentCondition);

    var self = this;
    paymentConditionForm.on("update", function (data) {
      self.handleObjectChanges(data.paymentCondition);
    });
  },
  methods: {
    handleObjectChanges(paymentCondition) {
      if (!!paymentCondition) {
        if (!!paymentCondition.description) {
          this.description = paymentCondition.description;
        }
      }
    },
    updateField(key, value) {
      paymentConditionForm.updateField(key, value);
    },
  },
  watch: {
    formValid(newVal) {
      this.$emit("update:valid", newVal);
      if (this.bus) {
        this.bus.$emit("update:valid", newVal);
      }
      paymentConditionForm.setValid(newVal);
    },
  },
};
</script>